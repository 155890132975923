import React, { useState, useEffect, useContext } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'
import { ButtonOrderContext } from '../../contexts/buttonOrderContext'
import Robot from './robot/robot'
import Car from './car/car'
import Unique from './unique/unique'
import Tree from './tree/tree'
import Responsive from './responsive/responsive'

const LandingModal = () => {
    const theme = useTheme()
    const { updateOrder } = useContext(ButtonOrderContext)
    const [ state, setState ] = useState({
        index:0
    })
    const LandingModalContainer = styled('div')({
        backgroundColor:theme.palette.gradients.raisinblack,
        background:`linear-gradient(0deg, ${theme.palette.gradients.raisinblack} 40%, ${theme.palette.gradients.raisinblack} 40%)`,
        height:'100%',
        paddingTop:'15px',
        [theme.breakpoints.up('sm')]:{
            paddingTop:'100px'
        }
    })
    const Title = styled('div')({
        padding:'5px',
        fontFamily:'Rubik',
        fontSize:'20px',
        textAlign:'center',
        backgroundColor:theme.palette.gradients.lavenderBlush,
    })
    const Subtitle = styled(Title)({
        backgroundColor:theme.palette.gradients.raisinblack,
        color:theme.palette.gradients.lightGreen,
        borderRight:`15px solid ${theme.palette.gradients.raisinblack}`,
        borderLeft:`15px solid ${theme.palette.gradients.raisinblack}`,
        borderBottom:`15px solid ${theme.palette.gradients.dodgerBlue}`,
        minHeight:'100px',
        [theme.breakpoints.up('sm')]:{
            minHeight:'0px',
            maxWidth:'70%',
            margin:'0 15%'
        },
        [theme.breakpoints.up('md')]:{
            maxWidth:'60%',
            margin:'0 20%'
        },
        [theme.breakpoints.up('lg')]:{
            maxWidth:'50%',
            margin:'0 25%'
        },
        [theme.breakpoints.up('xl')]:({
            maxWidth:'35%',
            margin:'0 32.5%',
        })
    })
    const SubtitleTop = styled(Subtitle)({
        borderBottom:'none',
        color:theme.palette.gradients.frostbite,
        borderTop:`15px solid ${theme.palette.gradients.dodgerBlue}`,
        marginTop:'15px auto',
        marginBottom:'0',
        minHeight:'0px',
        [theme.breakpoints.up('sm')]:{
            minHeight:'0px',
            maxWidth:'70%',
            margin:'0 15%',
            marginTop:'15px',
        },
        [theme.breakpoints.up('md')]:{
            maxWidth:'60%',
            margin:'0 20%',
            marginTop:'15px',
        },
        [theme.breakpoints.up('lg')]:{
            maxWidth:'50%',
            margin:'0 25%',
            marginTop:'15px',
        },
        [theme.breakpoints.up('xl')]:({
            maxWidth:'35%',
            margin:'0 32.5%',
            marginTop:'25px',
        })
    })
    const Divider = styled('div')({
        height:'3px',
        width:'80%',
        margin:'1% auto',
        backgroundColor:theme.palette.gradients.dodgerBlue,
        filter:'blur(3px)',
        [theme.breakpoints.up('sm')]:{
            maxWidth:'60%',
        },
        [theme.breakpoints.up('md')]:{
            maxWidth:'50%',
        },
        [theme.breakpoints.up('lg')]:{
            maxWidth:'35%',
        },
        [theme.breakpoints.up('xl')]:({
            maxWidth:'35%'
        })
    })
    const AnimationContainer = styled('div')({
        width:'100%', 
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        overflowX:'hidden',
        background:`linear-gradient(45deg,${theme.palette.gradients.dodgerBlue} 50%, ${theme.palette.gradients.zaffre} 50%)`,
        border:`15px solid ${theme.palette.gradients.dodgerBlue}`,
        borderBottomColor:theme.palette.gradients.zaffre,
        borderLeftColor:theme.palette.gradients.zaffre,
        '@keyframes rotateIn':{
            '0%':{
                transform:'scale(0) rotate(0deg)'
            },
            '100%':{
                transform:'scale(1) rotate(360deg)'
            }
        },
        '& > div':{
            transform:'scale(0) rotate(0deg)',
            width:'80%',
            height:'80%',
            animation:'rotateIn .5s linear .5s forwards',
            willChange:'transform',
            borderRadius:'10px',
            '& svg':{
                borderRadius:'10px',
                height:'100%'
            }
        }
    })
    const AbsoluteWrapper = styled('div')({
        position:'relative',
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        paddingTop:'80%',
        width:'80%',
        backgroundColor:theme.palette.gradients.lavenderBlush,
        [theme.breakpoints.up('xs')]:({
            width:'60%',
            paddingTop:'60%'
        }),
        [theme.breakpoints.up('sm')]:({
            width:'45%',
            paddingTop:'45%'
        }),
        [theme.breakpoints.up('md')]:({
            width:'35%',
            paddingTop:'35%'
        }),
        [theme.breakpoints.up('lg')]:({
            width:'25%',
            paddingTop:'25%'
        }),
        [theme.breakpoints.up('xl')]:({
            width:'15%',
            paddingTop:'15%'
        })
    })
    const AbsoluteContainer = styled('div')({
        position:'absolute',
        top:0,
        left:0,
        right:0,
        bottom:0
    })
    const GoToButtonContainer = styled('div')({
        width:'100%',
        padding:'25px',
        backgroundColor:theme.palette.gradients.raisinblack,
        minHeight:'105px',
    })
    const GoToButton = styled(Button)({
        width:'175px',
        height:'75px',
        backgroundColor:theme.palette.gradients.zaffre,
        color:theme.palette.gradients.lightGreen,
        border:`15px solid ${theme.palette.gradients.frenchViolet}`,
        boxShadow:`0px 0px 15px ${theme.palette.gradients.raisinblack}`,
        borderTopColor:theme.palette.gradients.frostbite,
        borderRightColor:theme.palette.gradients.frostbite,
        fontFamily:'Rubik',
        display:'block',
        marginRight:'auto',
        marginLeft:'auto',
        marginBottom:'25px',
    })
    const iconArray = [
        <Unique/>,
        <Car/>,
        <Tree/>,
        <Robot/>,
        <Responsive/>,
    ]
    const textArray = [
        'custom built and unique!',
        'optimize for blazing fast loading and navigation!',
        'scalable to grow with your business!',
        'built using the latest in modern web technologies!',
        'designed to display on any device!'
    ]
    const changeIndex = () => {
        const nextIndex = state.index === iconArray.length - 1 ? 0 : state.index + 1
        setState({...state,index:nextIndex})
    }
    useEffect(()=>{ 
        setTimeout(()=>{
            changeIndex()
        }, 4250)
    },[changeIndex])
    return(
        <LandingModalContainer>
            <AbsoluteWrapper>
            <AbsoluteContainer>
            <AnimationContainer>
                {
                    iconArray[state.index]
                }
            </AnimationContainer>
            </AbsoluteContainer>
            </AbsoluteWrapper>
            <SubtitleTop>
                A web solution from Lake is
            </SubtitleTop>
            <Divider/>
            <Subtitle>
                {
                    `${textArray[state.index]}`
                }
            </Subtitle>
            <GoToButtonContainer>
                <GoToButton onClick={()=>updateOrder('services')}>Go To Services</GoToButton>
            </GoToButtonContainer>
        </LandingModalContainer>
     )
}

export default LandingModal