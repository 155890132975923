import React from 'react'
import Animation from '../../animation/animation'
import { useTheme } from '@mui/material'

const Tree = () => {
  const trunkPaths = [
    "M 123.00,172.91 C 125.00,171.56 130.00,172.91 125.00,176.96 136.00,198.58 130.00,206.68 124.00,217.49 124.00,217.49 129.75,230.50 129.75,230.50 129.75,230.50 98.25,230.50 98.25,230.50 98.25,230.50 109.00,217.49 109.00,217.49 118.00,209.39 130.00,199.93 124.00,187.77 122.36,182.73 124.00,184.73 122.00,181.02 121.27,176.82 119.64,176.55 123.00,172.91 Z",
  "M 123.00,172.91 C 125.00,171.56 126.00,171.56 127.00,170.21 125.00,158.05 128.00,158.05 129.00,168.86 133.00,164.81 137.00,162.11 140.00,156.70 154.00,139.14 153.00,143.19 143.00,158.05 162.00,151.30 159.00,156.70 140.00,163.46 135.00,168.86 130.00,172.91 125.00,176.96 136.00,198.58 130.00,206.68 124.00,217.49 122.57,220.48 135.99,231.07 136.00,230.91 136.00,230.91 90.18,230.73 90.18,230.73 87.64,230.73 107.30,218.78 109.00,217.49 118.00,209.39 130.00,199.93 124.00,187.77 122.36,182.73 124.00,184.73 122.00,181.02 121.27,176.82 119.64,176.55 123.00,172.91 Z",
  "M 123.00,172.91 C 125.00,171.56 126.00,171.56 127.00,170.21 125.00,158.05 127.00,148.60 128.00,139.14 128.00,137.79 129.00,139.14 129.00,140.49 128.00,148.60 128.00,158.05 129.00,168.86 133.00,164.81 137.00,162.11 140.00,156.70 154.00,139.14 160.00,109.42 160.00,109.42 160.00,108.07 161.00,108.07 161.00,109.42 157.00,126.98 153.00,143.19 143.00,158.05 162.00,151.30 169.00,147.25 179.00,126.98 180.00,125.63 181.00,126.98 181.00,126.98 171.00,149.95 159.00,156.70 140.00,163.46 135.00,168.86 130.00,172.91 125.00,176.96 136.00,198.58 130.00,206.68 124.00,217.49 122.55,220.18 147.64,230.73 143.45,230.91 143.45,230.91 83.09,230.73 83.09,230.73 80.53,230.72 106.64,218.73 109.00,217.49 117.61,212.43 130.00,199.93 124.00,187.77 122.36,182.73 124.00,184.73 122.00,181.02 121.27,176.82 119.64,176.55 123.00,172.91 Z",
  "M 124.00,187.77 C 130.00,199.93 118.00,209.39 109.00,217.49 106.06,219.56 93.32,221.47 82.73,225.09 71.12,229.31 74.92,230.62 74.94,230.62 74.94,230.62 153.88,230.50 153.88,230.50 160.25,230.50 122.00,220.75 124.00,217.49 130.00,206.68 136.00,198.58 125.00,176.96 130.00,172.91 135.00,168.86 140.00,163.46 159.00,156.70 171.00,149.95 181.00,126.98 181.00,126.98 180.00,125.63 179.00,126.98 169.00,147.25 162.00,151.30 143.00,158.05 153.00,143.19 157.00,126.98 161.00,109.42 161.00,108.07 160.00,108.07 160.00,109.42 160.00,109.42 154.00,139.14 140.00,156.70 137.00,162.11 133.00,164.81 129.00,168.86 128.00,158.05 128.00,148.60 129.00,140.49 129.00,139.14 128.00,137.79 128.00,139.14 127.00,148.60 125.00,158.05 127.00,170.21 126.00,171.56 125.00,171.56 123.00,172.91 118.00,159.40 114.00,147.25 114.00,137.79 111.00,110.77 118.00,82.40 118.00,82.40 119.00,81.05 118.00,79.70 118.00,81.05 113.00,98.61 110.00,116.18 111.00,135.09 99.00,113.47 95.00,102.67 96.00,77.00 96.00,75.65 95.00,75.65 95.00,77.00 93.00,102.67 100.00,120.23 111.00,143.19 112.00,147.25 113.00,149.95 114.00,154.00 107.00,147.25 101.00,137.79 98.00,128.33 98.00,126.98 97.00,126.98 97.00,128.33 101.00,139.14 105.00,149.95 115.00,159.40 117.00,167.51 120.00,174.26 122.00,181.02 123.70,184.96 123.09,184.13 124.00,187.77 Z",
  "M 125.00,176.96 C 130.00,172.91 135.00,168.86 140.00,163.46 159.00,156.70 171.00,149.95 181.00,126.98 181.00,126.98 180.00,125.63 179.00,126.98 169.00,147.25 162.00,151.30 143.00,158.05 153.00,143.19 157.00,126.98 161.00,109.42 161.00,108.07 160.00,108.07 160.00,109.42 160.00,109.42 154.00,139.14 140.00,156.70 137.00,162.11 133.00,164.81 129.00,168.86 128.00,158.05 128.00,148.60 129.00,140.49 129.00,139.14 128.00,137.79 128.00,139.14 127.00,148.60 125.00,158.05 127.00,170.21 126.00,171.56 125.00,171.56 123.00,172.91 118.00,159.40 114.00,147.25 114.00,137.79 111.00,110.77 118.00,82.40 118.00,82.40 119.00,81.05 118.00,79.70 118.00,81.05 113.00,98.61 110.00,116.18 111.00,135.09 99.00,113.47 95.00,102.67 96.00,77.00 96.00,75.65 95.00,75.65 95.00,77.00 93.00,102.67 100.00,120.23 111.00,143.19 112.00,147.25 113.00,149.95 114.00,154.00 107.00,147.25 101.00,137.79 98.00,128.33 98.00,126.98 97.00,126.98 97.00,128.33 101.00,139.14 105.00,149.95 115.00,159.40 117.00,167.51 120.00,174.26 122.00,181.02 115.00,176.96 99.00,168.86 90.00,156.70 77.00,139.14 70.00,109.42 70.00,109.42 70.00,108.07 69.00,108.07 69.00,109.42 73.00,126.98 78.00,143.19 87.00,158.05 68.00,151.30 61.00,147.25 51.00,126.98 50.00,125.63 49.00,126.98 50.00,126.98 59.00,149.95 71.00,156.70 90.00,163.46 101.00,175.61 113.00,183.72 124.00,187.77 130.00,199.93 118.00,209.39 109.00,217.49 102.56,221.31 44.32,231.76 47.00,231.00 47.00,231.00 193.00,231.00 193.00,231.00 193.11,231.14 120.50,222.88 124.00,217.49 130.00,206.68 136.00,198.58 125.00,176.96 Z"
  ]
  
  const leafPaths = [
  "M 129.00,182.65 C 132.00,177.25 138.00,177.25 141.00,182.65 138.00,188.05 132.00,188.05 129.00,182.65 Z",
  "M 120.00,172.74 C 117.00,165.98 110.00,164.63 107.00,170.04 110.00,176.79 117.00,178.14 120.00,172.74 Z",
  "M 120.00,184.84C 116.00,178.09 110.00,176.74 107.00,182.14 110.00,188.89 117.00,191.60 120.00,184.84 Z",
  "M 126.00,169.30 C 126.00,161.19 122.00,153.09 117.00,155.79 116.00,163.89 121.00,172.00 126.00,169.30 Z",
  "M 130.00,174.00 C 129.00,164.54 135.00,157.79 141.00,160.49 141.00,168.60 135.00,175.35 130.00,174.00 Z",
  "M 129.00,163.14 C 127.00,153.68 130.00,144.23 136.00,144.23 138.00,152.33 135.00,161.79 129.00,163.14 Z",
  "M 159.00,131.30 C 153.00,127.25 146.00,132.65 145.00,143.46 151.00,146.16 158.00,140.75 159.00,131.30 Z",
  "M 144.00,149.70 C 145.00,140.25 141.00,132.14 135.00,132.14 134.00,141.60 138.00,149.70 144.00,149.70 Z",
  "M 151.00,150.65 C 154.00,145.25 160.00,145.25 163.00,150.65 160.00,156.05 154.00,156.05 151.00,150.65 Z",
  "M 145.00,162.25 C 152.00,158.19 159.00,166.30 159.00,177.11 152.00,179.81 145.00,173.05 145.00,162.25 Z",
  "M 175.00,141.84 C 178.00,136.44 184.00,136.44 187.00,143.19 184.00,148.60 178.00,147.25 175.00,141.84 Z",
  "M 179.00,151.30 C 184.00,147.25 191.00,151.30 192.00,160.75 187.00,164.81 180.00,159.40 179.00,151.30 Z",
  "M 160.00,122.93 C 159.00,114.82 164.00,106.72 170.00,106.72 171.00,114.82 166.00,122.93 160.00,122.93 Z",
  "M 162.00,104.02 C 158.00,98.61 158.00,90.51 162.00,85.11 166.00,90.51 166.00,98.61 162.00,104.02 Z",
  "M 170.00,104.02 C 176.00,104.02 181.00,97.26 181.00,89.16 175.00,87.81 170.00,94.56 170.00,104.02 Z",
  "M 161.00,145.89 C 157.00,141.84 157.00,132.39 161.00,128.33 164.00,132.39 164.00,141.84 161.00,145.89 Z",
  "M 181.00,133.74 C 182.00,124.28 189.00,118.88 195.00,122.93 193.00,132.39 187.00,137.79 181.00,133.74 Z",
  "M 166.00,140.49 C 162.00,132.39 164.00,121.58 170.00,118.88 174.00,125.63 172.00,136.44 166.00,140.49 Z",
  "M 178.00,120.23 C 176.00,109.42 181.00,98.61 188.00,97.26 190.00,108.07 185.00,118.88 178.00,120.23 Z",
  "M 141.00,106.72 C 140.00,118.88 146.00,128.33 154.00,126.98 154.00,114.82 148.00,106.72 141.00,106.72 Z",
  "M 125.00,151.30 C 125.00,143.19 121.00,135.09 116.00,137.79 115.00,145.89 120.00,154.00 125.00,151.30 Z",
  "M 100.00,141.84 C 96.00,135.09 90.00,133.74 87.00,139.14 90.00,145.89 97.00,148.60 100.00,141.84 Z",
  "M 120.00,83.75 C 119.00,75.65 123.00,67.54 129.00,67.54 130.00,75.65 126.00,83.75 120.00,83.75 Z",
  "M 117.00,79.70 C 118.00,70.25 114.00,62.14 108.00,62.14 107.00,71.60 111.00,79.70 117.00,79.70 Z",
  "M 102.00,108.07 C 101.00,99.96 105.00,91.86 111.00,91.86 112.00,99.96 108.00,108.07 102.00,108.07 Z",
  "M 117.00,131.04 C 122.00,136.44 129.00,133.74 131.00,125.63 127.00,120.23 120.00,122.93 117.00,131.04 Z",
  "M 98.00,93.21 C 97.00,83.75 102.00,75.65 107.00,75.65 109.00,85.11 104.00,93.21 98.00,93.21 Z",
  "M 116.00,120.23 C 122.00,124.28 130.00,118.88 132.00,108.07 125.00,102.67 117.00,109.42 116.00,120.23 Z",
  "M 157.00,114.82 C 150.00,110.77 147.00,98.61 151.00,89.16 157.00,91.86 160.00,105.37 157.00,114.82 Z",
  "M 116.00,105.37 C 124.00,108.07 130.00,98.61 130.00,87.81 123.00,86.46 116.00,94.56 116.00,105.37 Z",
  "M 97.00,72.95 C 94.00,63.49 98.00,51.33 106.00,49.98 108.00,59.44 104.00,71.60 97.00,72.95 Z",
  "M 80.00,106.72 C 78.00,95.91 83.00,85.11 90.00,85.11 92.00,95.91 87.00,106.72 80.00,106.72 Z",
  "M 80.00,86.46 C 78.00,75.65 84.00,66.19 91.00,64.84 93.00,75.65 87.00,86.46 80.00,86.46 Z",
  "M 91.00,62.14 C 84.00,64.84 77.00,55.39 78.00,44.58 85.00,43.23 92.00,51.33 91.00,62.14 Z",
  "M 77.00,124.28 C 76.00,114.82 81.00,106.72 87.00,106.72 88.00,116.18 83.00,124.28 77.00,124.28 Z",
  "M 83.00,139.14 C 81.00,129.68 84.00,120.23 90.00,120.23 92.00,128.33 89.00,137.79 83.00,139.14 Z",
  "M 50.00,133.74 C 47.00,126.98 40.00,125.63 37.00,131.04 40.00,137.79 47.00,139.14 50.00,133.74 Z",
  "M 61.00,151.30 C 55.00,147.25 48.00,152.65 47.00,163.46 53.00,166.16 60.00,160.75 61.00,151.30 Z",
  "M 78.00,162.11 C 79.00,172.91 74.00,182.37 66.00,182.37 65.00,171.56 71.00,162.11 78.00,162.11 Z",
  "M 67.00,104.02 C 66.00,95.91 70.00,87.81 76.00,87.81 77.00,95.91 73.00,104.02 67.00,104.02 Z",
  "M 71.00,158.05 C 65.00,155.35 58.00,160.75 57.00,170.21 63.00,174.26 70.00,168.86 71.00,158.05 Z",
  "M 70.00,125.63 C 68.00,133.74 62.00,137.79 57.00,133.74 59.00,125.63 65.00,121.58 70.00,125.63 Z",
  "M 55.00,144.54 C 52.00,151.30 45.00,152.65 41.00,145.89 44.00,139.14 51.00,137.79 55.00,144.54 Z",
  "M 48.00,125.63 C 41.00,128.33 34.00,120.23 34.00,109.42 41.00,106.72 48.00,114.82 48.00,125.63 Z",
  "M 68.00,113.47 C 64.00,122.93 55.00,124.28 50.00,116.18 54.00,106.72 63.00,105.37 68.00,113.47 Z",
  "M 62.00,97.26 C 56.00,105.37 48.00,102.67 44.00,93.21 49.00,86.46 58.00,87.81 62.00,97.26 Z",
  "M 148.00,77.00 C 144.00,86.46 135.00,87.81 130.00,79.70 134.00,70.25 142.00,68.89 148.00,77.00 Z",
  "M 123.00,66.19 C 116.00,67.54 109.00,59.44 109.00,48.63 116.00,45.93 123.00,55.39 123.00,66.19 Z",
  "M 124.00,47.28 C 117.00,44.58 114.00,32.42 118.00,22.96 125.00,25.67 128.00,39.18 124.00,47.28 Z",
  "M 107.00,48.63 C 103.00,39.18 106.00,27.02 113.00,22.96 116.00,32.42 113.00,44.58 107.00,48.63 Z",
  "M 33.00,122.93 C 27.00,129.68 18.00,126.98 15.00,117.53 21.00,110.77 30.00,113.47 33.00,122.93 Z",
  "M 34.00,101.32 C 31.00,112.12 23.00,116.18 17.00,109.42 19.00,98.61 28.00,95.91 34.00,101.32 Z",
  "M 45.00,97.26 C 38.00,104.02 30.00,98.61 28.00,87.81 34.00,82.40 42.00,87.81 45.00,97.26 Z",
  "M 165.00,152.65 C 168.00,147.25 174.00,147.25 177.00,152.65 174.00,158.05 168.00,158.05 165.00,152.65 Z",
  "M 92.00,49.98 C 85.00,45.93 82.00,33.77 86.00,24.32 93.00,28.37 96.00,40.53 92.00,49.98 Z",
  "M 96.00,55.39 C 92.00,45.93 95.00,33.77 102.00,29.72 105.00,39.18 103.00,52.68 96.00,55.39 Z",
  "M 204.00,132.39 C 200.00,124.28 202.00,112.12 208.00,108.07 212.00,116.18 210.00,128.33 204.00,132.39 Z",
  "M 196.00,113.47 C 189.00,108.07 187.00,95.91 192.00,86.46 198.00,91.86 200.00,104.02 196.00,113.47 Z",
  "M 168.00,90.51 C 176.00,91.86 182.00,82.40 182.00,71.60 175.00,70.25 168.00,78.35 168.00,90.51 Z",
  "M 145.00,66.19 C 140.00,72.95 131.00,71.60 128.00,62.14 133.00,54.04 142.00,56.74 145.00,66.19 Z",
  "M 63.00,90.51 C 63.00,79.70 69.00,70.25 76.00,71.60 77.00,82.40 71.00,91.86 63.00,90.51 Z",
  "M 156.00,81.05 C 152.00,71.60 154.00,59.44 161.00,55.39 165.00,63.49 163.00,77.00 156.00,81.05 Z",
  "M 143.00,56.74 C 136.00,59.44 129.00,49.98 129.00,39.18 137.00,37.82 143.00,45.93 143.00,56.74 Z",
  "M 191.00,56.74 C 189.00,45.93 194.00,35.12 201.00,33.77 203.00,44.58 198.00,55.39 191.00,56.74 Z",
  "M 199.00,70.25 C 201.00,60.79 209.00,55.39 216.00,60.79 213.00,71.60 205.00,77.00 199.00,70.25 Z",
  "M 146.00,28.37 C 142.00,18.91 144.00,6.75 151.00,1.35 155.00,10.81 153.00,22.96 146.00,28.37 Z",
  "M 159.00,39.18 C 154.00,31.07 157.00,17.56 163.00,13.51 168.00,22.96 165.00,35.12 159.00,39.18 Z",
  "M 173.00,51.33 C 170.00,40.53 175.00,29.72 183.00,28.37 185.00,39.18 180.00,49.98 173.00,51.33 Z",
  "M 45.00,64.84 C 38.00,60.79 37.00,47.28 41.00,39.18 48.00,43.23 50.00,56.74 45.00,64.84 Z",
  "M 105.00,28.37 C 100.00,20.26 103.00,6.75 109.00,2.70 114.00,12.16 111.00,24.32 105.00,28.37 Z",
  "M 125.00,25.67 C 118.00,21.61 115.00,9.46 119.00,0.00 125.00,4.05 128.00,16.21 125.00,25.67 Z",
  "M 128.00,39.18 C 124.00,29.72 127.00,17.56 134.00,13.51 137.00,22.96 134.00,35.12 128.00,39.18 Z",
  "M 193.00,135.09 C 200.00,129.68 208.00,135.09 210.00,145.89 203.00,151.30 195.00,144.54 193.00,135.09 Z",
  "M 200.00,105.37 C 202.00,95.91 210.00,90.51 217.00,95.91 215.00,105.37 207.00,110.77 200.00,105.37 Z",
  "M 202.00,95.91 C 199.00,85.11 204.00,74.30 212.00,74.30 214.00,83.75 209.00,95.91 202.00,95.91 Z",
  "M 191.00,147.25 C 198.00,143.19 205.00,151.30 205.00,162.11 198.00,164.81 191.00,158.05 191.00,147.25 Z",
  "M 166.00,62.14 C 161.00,54.04 161.00,40.53 167.00,35.12 173.00,41.88 172.00,55.39 166.00,62.14 Z",
  "M 95.00,33.77 C 90.00,25.67 91.00,12.16 98.00,6.75 103.00,14.86 101.00,28.37 95.00,33.77 Z",
  "M 69.00,147.79 C 68.00,139.68 74.00,132.93 80.00,134.28 80.00,143.74 75.00,150.49 69.00,147.79 Z",
  "M 83.00,41.88 C 76.00,44.58 69.00,35.12 70.00,24.32 77.00,22.96 84.00,31.07 83.00,41.88 Z",
  "M 65.00,51.33 C 58.00,52.68 51.00,44.58 52.00,33.77 59.00,31.07 65.00,40.53 65.00,51.33 Z",
  "M 75.00,62.14 C 68.00,59.44 66.00,45.93 69.00,36.47 76.00,40.53 79.00,52.68 75.00,62.14 Z",
  "M 17.00,125.63 C 12.00,132.39 3.00,128.33 0.00,118.88 6.00,112.12 14.00,114.82 17.00,125.63 Z",
  "M 33.00,85.11 C 26.00,86.46 19.00,78.35 19.00,67.54 27.00,64.84 33.00,72.95 33.00,85.11 Z",
  "M 29.00,94.56 C 24.00,101.32 15.00,99.96 11.00,90.51 16.00,82.40 25.00,85.11 29.00,94.56 Z",
  "M 29.00,94.56 C 24.00,101.32 15.00,99.96 11.00,90.51 16.00,82.40 25.00,85.11 29.00,94.56 Z",
  "M 57.00,85.11 C 50.00,87.81 43.00,78.35 43.00,67.54 50.00,66.19 57.00,74.30 57.00,85.11 Z",
  "M 61.00,78.35 C 57.00,68.89 60.00,56.74 67.00,52.68 71.00,62.14 68.00,75.65 61.00,78.35 Z",
  "M 57.00,72.95 C 50.00,68.89 47.00,56.74 51.00,47.28 58.00,51.33 61.00,63.49 57.00,72.95 Z",
  "M 52.00,167.51 C 54.00,178.32 49.00,189.12 41.00,189.12 40.00,178.32 45.00,167.51 52.00,167.51 Z",
  "M 40.00,159.40 C 41.00,170.21 36.00,181.02 28.00,179.67 27.00,168.86 33.00,159.40 40.00,159.40 Z",
  "M 30.00,159.40 C 27.00,170.21 18.00,172.91 12.00,167.51 15.00,156.70 23.00,152.65 30.00,159.40 Z",
  "M 36.00,154.00 C 30.00,148.60 28.00,136.44 32.00,126.98 39.00,132.39 41.00,145.89 36.00,154.00 Z",
  "M 28.00,149.95 C 21.00,149.95 16.00,139.14 17.00,128.33 25.00,129.68 30.00,139.14 28.00,149.95 Z",
  "M 23.00,151.30 C 17.00,158.05 8.00,155.35 5.00,145.89 11.00,139.14 20.00,141.84 23.00,151.30 Z",
  "M 209.00,132.39 C 215.00,126.98 223.00,131.04 226.00,140.49 220.00,147.25 211.00,143.19 209.00,132.39 Z",
  "M 214.00,124.28 C 217.00,114.82 226.00,113.47 231.00,120.23 228.00,129.68 219.00,132.39 214.00,124.28 Z",
  "M 214.00,118.88 C 211.00,108.07 216.00,97.26 223.00,95.91 226.00,106.72 221.00,117.53 214.00,118.88 Z",
  "M 168.00,72.95 C 167.00,62.14 172.00,52.68 180.00,52.68 181.00,63.49 176.00,72.95 168.00,72.95 Z",
  "M 186.00,82.40 C 190.00,71.60 198.00,70.25 204.00,77.00 200.00,86.46 191.00,89.16 186.00,82.40 Z",
  "M 186.00,74.30 C 186.00,62.14 192.00,54.04 200.00,56.74 200.00,67.54 193.00,75.65 186.00,74.30 Z",
  "M 183.00,68.89 C 178.00,60.79 181.00,47.28 187.00,43.23 192.00,51.33 189.00,64.84 183.00,68.89 Z",
  "M 149.00,62.14 C 148.00,51.33 154.00,40.53 161.00,41.88 162.00,52.68 156.00,62.14 149.00,62.14 Z",
  "M 147.00,49.98 C 144.00,40.53 147.00,28.37 153.00,24.32 157.00,33.77 154.00,45.93 147.00,49.98 Z",
  "M 144.00,44.58 C 137.00,40.53 134.00,28.37 138.00,18.91 145.00,22.96 148.00,35.12 144.00,44.58 Z"
  ]
    const theme = useTheme()
    const counter = {
      switchAfter:[5,10,20,36,46,63,79,95,leafPaths.length - 1],
      waveDelays:[0,0.25,0.5,0.75,1,1.25,1.5,1.75,2.0],
      leafDelays:[0.05,0.05,0.025,0.014706,0.015625,0.015625,0.015625,0.017857, 0.015],
      waveIndex:0,
      leafDelay:0,
      trunkDelay:0
    }
    const colors = Object.assign({},...Object.keys(theme.palette.gradients).map((color)=>({
      [color]:theme.palette.gradients[color]
    })))
    const getRandomColor = () => {
      return[
      colors.lightGreen,
      colors.frostbite,
      colors.mountainMeadow,
      colors.frenchViolet,
      colors.dodgerBlue,
      colors.zaffre,
      colors.darkorange
    ][Math.floor(Math.random() * 7)]}
    const pathData = [
      {
        id:'trunkGroup',
        group:true,
        paths:trunkPaths.map((path,key)=>{
          
          return{
            id:`trunk${key}`,
            d:path,
            color:theme.palette.gradients.blastOffBronze,
            stroke:colors.dodgerBlue,
            animate:true,
            type:'css',
            attributeName:'opacity',
            values:'0;0;1;1',
            keyTimes:`0;${parseFloat(.15 * key) - .05};${parseFloat(.15 * key)};1`,
            dur:3,
            delay:1,
            repeatCount:0,
            styles:{
              opacity:0
            }
          }
        })
      },
      {
        id:'leaves',
        group:true,
        paths:leafPaths.map((path,key)=>{
          if(key > counter.switchAfter[counter.waveIndex]){
            counter.waveIndex++
          }
          counter.leafDelay += counter.leafDelays[counter.waveIndex]
          return (
            {
              id:`leaf${key}`,
              d:path,
              color:getRandomColor(),
              stroke:colors.frostbite,
              strokeWidth:1,
              animate:true,
              type:'css',
              attributeName:'opacity',
              values:'0;0;1;1',
              keyTimes:`0;${parseFloat(0.8/(leafPaths.length + 1) * key)};${parseFloat(0.8/(leafPaths.length + 1) * key) +.05};1`,
              dur:3,
              delay:1,
              repeatCount:0,
              styles:{
                opacity:0
              }
            }
          )
        })
      }
    ]
    return(
        <Animation 
            pathData={pathData}  
            height='auto'  
            width='100%'
            viewBox="0 0 231 231"
            backgroundColor={theme.palette.gradients.raisinblack}
        />)
 }

export default Tree
