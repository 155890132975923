import React from 'react'
import Animation from '../../animation/animation'
import { useTheme } from '@mui/material'

const Car = () => {
    const theme = useTheme()
    const pathData = [
        {
            id:'carGroup',
            group:true,
            styles:{
                backgroundColor:theme.palette.gradients.lavenderBlush
            },
            animate:true,
            type:'translate',
            values:'0 0;0 0;-2000 0;-2000 0',
            keyTimes:'0;.5;.55;1',
            dur:3,
            delay:1,
            repeatCount:0,
            paths:[
                {
                    id:'carFrame',
                    d:'M 2.50,56.50 C 2.50,56.50 36.00,41.75 36.00,41.75 36.00,41.75 41.12,40.38 41.12,40.38 41.12,40.38 70.62,33.75 70.62,33.75 70.62,33.75 87.75,31.25 87.75,31.25 87.75,31.25 106.62,20.25 106.62,20.25 106.62,20.25 139.75,20.38 139.75,20.25 139.75,20.12 156.12,24.62 156.12,24.62 156.12,24.62 156.38,20.75 156.38,20.75 156.38,20.75 148.12,18.88 148.12,18.88 148.12,18.88 148.25,12.38 148.25,12.38 148.25,12.38 149.62,7.00 149.62,7.00 149.62,7.00 158.25,7.00 158.25,7.00 158.25,7.00 158.38,11.50 158.38,11.50 158.38,11.50 177.00,12.75 177.00,12.75 177.00,12.75 215.38,27.62 215.38,27.62 215.38,27.62 231.25,27.50 231.25,27.50 231.25,27.50 231.12,17.62 231.12,17.62 231.12,17.62 234.75,13.88 234.75,13.88 234.75,13.88 241.38,13.75 241.38,13.75 241.38,13.75 244.75,16.50 244.75,16.50 244.75,16.50 250.88,10.25 250.88,10.25 250.88,10.25 266.25,10.38 266.25,10.38 266.25,10.38 266.25,22.00 266.25,22.00 266.25,22.00 264.00,29.88 264.00,29.88 264.00,29.88 259.88,34.25 259.88,34.25 259.88,34.25 260.12,36.62 260.12,36.62 260.12,36.62 265.25,36.75 265.25,36.75 265.25,36.75 265.50,40.88 265.50,40.88 265.50,40.88 262.50,44.25 262.50,44.25 262.50,44.25 225.50,51.50 225.50,51.50 225.50,51.50 219.38,52.25 219.38,52.25 219.38,52.25 209.50,58.38 209.50,58.38 209.50,58.38 95.12,63.38 95.12,63.38 95.12,63.38 95.25,58.38 95.25,58.38 95.25,58.38 90.12,50.12 90.12,50.12 90.12,50.12 75.62,50.00 75.62,50.00 75.62,50.00 45.00,56.12 45.00,56.12 45.00,56.12 37.12,56.12 37.12,56.12 37.12,56.12 37.25,60.38 37.25,60.38 37.25,60.38 18.75,64.00 18.75,64.00 18.75,64.00 14.75,63.88 14.75,63.88 14.75,63.88 2.50,56.50 2.50,56.50 Z',
                    color:theme.palette.gradients.frostbite,
                    stroke:theme.palette.gradients.zaffre,
                    strokeWidth:2,
                },
                {
                    id:'carStripe',
                    d:'M 5.39,58.24 C 6.94,59.17 8.88,60.34 10.58,61.37 10.89,61.55 21.64,54.55 29.44,52.44 46.18,47.64 69.82,44.91 96.27,44.86 194.73,45.64 264.00,29.88 264.00,29.88 264.00,29.88 266.25,22.00 266.25,22.00 268.63,29.30 118.31,39.43 96.00,39.12 78.75,39.00 41.09,43.45 28.36,48.36 24.00,50.00 3.26,59.17 5.39,58.24 Z',
                    color:theme.palette.gradients.zaffre,
                    stroke:theme.palette.gradients.dodgerBlue,
                    strokeWidth:2
                },
                {
                    id:'carWindow',
                    d:'M 102.88,29.62 C 102.88,29.62 112.25,21.75 112.25,21.75 112.25,21.75 130.62,21.75 130.62,21.75 135.00,22.12 147.91,25.63 132.00,31.00 124.38,31.00 115.62,30.88 115.62,30.88 115.62,30.88 115.66,28.62 115.66,28.62 115.66,28.62 118.50,28.62 118.50,28.62 118.50,28.62 118.56,25.41 118.56,25.41 118.56,25.41 109.75,25.41 109.75,25.41 109.75,25.41 109.72,28.53 109.72,28.53 109.72,28.53 110.69,28.50 110.69,28.50 110.69,28.50 110.75,29.72 110.75,29.72 110.75,29.72 102.88,29.62 102.88,29.62 Z',
                    color:theme.palette.gradients.frostbiteHalf
                },
                {
                    id:'carDriver',
                    d:'M 131.97,30.94 C 129.22,30.94 128.69,30.91 128.73,31.09 128.73,31.09 125.18,24.18 132.64,23.00 137.18,23.18 138.00,25.36 137.73,26.82 136.09,29.91 133.44,30.47 133.44,30.47 133.44,30.47 133.25,30.56 131.97,30.94 Z',
                    color:theme.palette.gradients.raisinblack
                },
                {
                    id:'frontTireGroup',
                    group:true,
                    paths:[
                        {
                            id:'frontTire',
                            d:'M 55.00,29.31 C 57.22,29.17 58.72,28.94 61.00,29.31 86.35,30.86 84.08,69.72 58.00,67.90 37.24,66.46 32.14,36.36 55.00,29.31 Z',
                            color:theme.palette.gradients.raisinblack,
                        },
                        {
                            id:'spokesFront',
                            d:'M 59.44,44.35 C 59.44,44.35 59.57,35.61 59.57,35.61M 59.31,53.88 C 59.31,53.88 59.31,61.44 59.31,61.44M 54.52,48.96 C 54.52,48.96 46.39,48.91 46.39,48.91M 64.12,49.02 C 64.12,49.02 71.90,49.10 71.90,49.10M 62.78,45.48 C 62.78,45.48 68.13,39.31 68.13,39.31M 62.64,52.46 C 62.64,52.46 68.14,57.21 68.14,57.21M 56.13,52.66 C 56.13,52.66 50.75,58.16 50.75,58.16M 56.14,45.57 C 56.14,45.57 50.51,39.45 50.51,39.45M 57.66,44.78 C 65.57,43.57 65.30,51.60 61.22,53.22 56.56,55.07 51.61,49.02 57.66,44.78 Z M 56.00,34.36 C 57.95,34.23 58.97,33.99 61.00,34.36 79.84,36.09 77.10,64.72 58.00,62.80 42.96,61.29 39.43,39.84 56.00,34.36 Z',
                            stroke:theme.palette.gradients.dodgerBlue,
                            strokeWidth:2,
                            animate:true,
                            type:'rotate',
                            values:'0 0 0;-360 0 0',
                            keyTimes:'0;1',
                            dur:.5,
                            delay:1,
                            repeatCount:0,
                        }
                    ]
                },
                {
                    id:'backTireGroup',
                    group:true,
                    paths:[
                        {
                            id:'backTire',
                            d:'M 238.00,29.31 C 240.22,29.17 241.72,28.94 244.00,29.31 269.35,30.86 267.08,69.72 241.00,67.90 220.24,66.46 215.14,36.36 238.00,29.31 Z M -287.00,-58.00',
                            color:theme.palette.gradients.raisinblack,
                        },
                        {
                            id:'spokesBack',
                            d:'M 242.44,44.47 C 242.44,44.47 242.57,35.73 242.57,35.73M 242.31,54.00 C 242.31,54.00 242.31,61.56 242.31,61.56M 237.52,49.08 C 237.52,49.08 229.39,49.04 229.39,49.04M 247.12,49.14 C 247.12,49.14 254.90,49.23 254.90,49.23M 245.78,45.60 C 245.78,45.60 251.13,39.43 251.13,39.43M 245.64,52.58 C 245.64,52.58 251.14,57.33 251.14,57.33M 239.13,52.78 C 239.13,52.78 233.75,58.28 233.75,58.28M 239.14,45.70 C 239.14,45.70 233.51,39.57 233.51,39.57M 240.66,44.91 C 248.57,43.69 248.30,51.72 244.22,53.34 239.56,55.20 234.61,49.15 240.66,44.91 Z M 239.00,34.49 C 240.95,34.35 241.97,34.12 244.00,34.49 262.84,36.21 260.10,64.84 241.00,62.93 225.96,61.42 222.43,39.97 239.00,34.49 Z',
                            stroke:theme.palette.gradients.dodgerBlue,
                            strokeWidth:2,
                            animate:true,
                            animate:true,
                            type:'rotate',
                            values:'0 0 0;-360 0 0',
                            keyTimes:'0;1',
                            dur:.5,
                            delay:1,
                            repeatCount:0,
                        },
                    ]
                },                
            ]
        },
        {
            id:'zoomGroup',
            group:true,
            animate:true,
            type:'css',
            attributeName:'opacity',
            values:'0;0;1;1',
            keyTimes:'0;0.5;0.55;1',
            dur:3,
            delay:1,
            repeatCount:0,
            styles:{
                opacity:0
            },
            paths:[
                {
                    id:'smokeInner',
                    d:'M 194.36,35.45 C 194.36,35.45 212.36,35.45 212.36,35.45M 211.44,53.00 C 209.73,60.20 223.00,62.45 224.00,58.25 221.20,69.59 252.49,74.87 242.78,56.52 243.31,60.21 262.81,59.58 255.91,45.52 273.00,35.54 255.36,27.31 255.55,27.27 263.00,21.94 251.66,5.02 241.45,16.18 241.45,16.18 243.30,13.91 246.31,12.66 238.13,-5.89 222.36,9.64 224.18,13.64 213.09,5.82 211.64,18.00 211.64,18.00 211.57,19.74 213.48,24.17 213.09,23.64 213.09,23.64 185.82,32.18 185.82,32.18 185.82,32.18 185.82,39.45 185.82,39.45 185.82,39.45 213.27,47.45 213.27,47.45 213.27,47.45 211.44,53.00 211.44,53.00 Z M 225.31,47.31',
                    color:'#c1c1c1',
                    animate:true,
                    type:'scale',
                    values:'1;1;1.3',
                    keyTimes:'0;.55;1',
                    dur:3,
                    delay:1,
                    repeatCount:0
                },
                {
                    id:'zoomZ',
                    d:'M 57.56,45.00 C 57.56,45.00 57.56,53.00 57.56,53.00 57.56,53.00 29.83,53.00 29.83,53.00 29.83,53.00 29.83,46.00 29.83,46.00 29.83,46.00 44.59,28.00 44.59,28.00 44.59,28.00 30.95,28.00 30.95,28.00 30.95,28.00 30.95,20.00 30.95,20.00 30.95,20.00 57.09,20.00 57.09,20.00 57.09,20.00 57.09,27.00 57.09,27.00 57.09,27.00 42.77,45.00 42.77,45.00 42.77,45.00 57.56,45.00 57.56,45.00 Z',
                    color:theme.palette.gradients.frostbite,
                    stroke:theme.palette.gradients.zaffre,
                    strokeWidth:3,
                    animate:true,
                    type:'skewX',
                    values:'0;0;20;0;0',
                    keyTimes:'0;.52;.55;.75;1',
                    dur:3,
                    delay:1,
                    repeatCount:0
                },
                {
                    id:'zoomO',
                    d:'M 82.23,62.00 C 75.67,62.00 70.32,59.79 66.19,55.36 62.05,50.92 59.98,44.98 59.98,37.55 59.98,30.45 61.96,24.58 65.91,19.95 69.86,15.32 75.35,13.00 82.38,13.00 88.86,13.00 94.15,15.18 98.23,19.55 102.33,23.91 104.38,29.74 104.38,37.03 104.38,44.57 102.31,50.62 98.19,55.17 94.06,59.73 88.74,62.00 82.23,62.00 Z M 82.16,51.00 C 84.57,51.00 86.31,49.95 87.38,47.83 88.44,45.71 88.97,41.84 88.97,36.22 88.97,28.07 86.79,24.00 82.44,24.00 77.74,24.00 75.39,28.64 75.39,37.92 75.39,46.64 77.64,51.00 82.16,51.00 Z',
                    color:theme.palette.gradients.frostbite,
                    stroke:theme.palette.gradients.zaffre,
                    strokeWidth:3,
                    animate:true,
                    type:'skewX',
                    values:'0;0;20;0;0',
                    keyTimes:'0;.51;.54;.75;1',
                    dur:3,
                    delay:1,
                    repeatCount:0
                },
                {
                    id:'zoomO2',
                    d:'M 122.31,55.00 C 117.62,55.00 113.80,53.42 110.84,50.25 107.89,47.09 106.42,42.84 106.42,37.53 106.42,32.46 107.83,28.27 110.66,24.97 113.48,21.66 117.39,20.00 122.41,20.00 127.04,20.00 130.82,21.56 133.73,24.69 136.66,27.80 138.12,31.96 138.12,37.17 138.12,42.56 136.65,46.88 133.70,50.12 130.77,53.38 126.97,55.00 122.31,55.00 Z M 122.27,47.00 C 123.99,47.00 125.23,46.26 125.98,44.77 126.74,43.28 127.12,40.55 127.12,36.59 127.12,30.87 125.57,28.00 122.45,28.00 119.10,28.00 117.42,31.27 117.42,37.80 117.42,43.93 119.04,47.00 122.27,47.00 Z',
                    color:theme.palette.gradients.frostbite,
                    stroke:theme.palette.gradients.zaffre,
                    strokeWidth:3,
                    animate:true,
                    type:'skewX',
                    values:'0;0;20;0;0',
                    keyTimes:'0;.50;.53;.75;1',
                    dur:3,
                    delay:1,
                    repeatCount:0
                },
                {
                    id:'zoomM',
                    d:'M 177.14,21.00 C 177.14,21.00 177.14,54.00 177.14,54.00 177.14,54.00 167.64,54.00 167.64,54.00 167.64,54.00 167.64,30.41 167.64,30.41 167.64,30.41 161.47,54.00 161.47,54.00 161.47,54.00 154.97,54.00 154.97,54.00 154.97,54.00 148.58,30.41 148.58,30.41 148.58,30.41 148.58,54.00 148.58,54.00 148.58,54.00 140.91,54.00 140.91,54.00 140.91,54.00 140.91,21.00 140.91,21.00 140.91,21.00 154.27,21.00 154.27,21.00 154.27,21.00 159.09,38.53 159.09,38.53 159.09,38.53 163.70,21.00 163.70,21.00 163.70,21.00 177.14,21.00 177.14,21.00 Z',
                    color:theme.palette.gradients.frostbite,
                    stroke:theme.palette.gradients.zaffre,
                    strokeWidth:3,
                    animate:true,
                    type:'skewX',
                    values:'0;0;20;0;0',
                    keyTimes:'0;.5;.53;.75;1',
                    dur:3,
                    delay:1,
                    repeatCount:0
                },
            ]
        }
    ]
    return(
        <Animation 
            pathData={pathData}  
            height='auto'  
            width='100%'
            viewBox="0 -100 272 272"
            backgroundColor={theme.palette.gradients.raisinblack}
        />)
 }

export default Car